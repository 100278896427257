import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <>
      <div className='row text-white' style={{ backgroundColor: "black", fontSize: "12px" }}>
        <div className='col-md-8' style={{
          borderRight: '1px solid white'
        }}>
          <h6>Techinfolab360</h6>
          <p> <fagmail/>Email: techinfolab360@gmail.com || Website: <a href="https://www.techinfolab360.xyz" style={{ color: 'white' }}>https://www.techinfolab360.xyz</a> <br/>
            
          </p>
          <p>Address: Islamia Model School, Sohan, Islamabad</p>
        
        </div>
        <div className='col-md-4'>
        <a href="https://web.facebook.com/profile.php?id=61555994156467" target="_blank" rel="noopener noreferrer" style={{ color: 'white', marginRight: '10px', fontSize:"30px" }}>
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            {/* <a href="https://wa.me/yourwhatsappnumber" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
              <FontAwesomeIcon icon={faWhatsapp} />
            </a> */}
        </div>
       
      </div>
    </>
  );
};

export default Footer;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import principal from '../Images/principal.jpg';
import dashboard from '../Images/dashboard.jpg';



const About = () => {





  return (
    <>

      <div className='row'>
        <div className='col-md-6 px-5 py-5'>
          <img src={principal} alt="Principal" className="img-fluid" />
        </div>
        <div className='col-md-6 px-5 py-5'>
          <h4 style={{ color: "blue" }}>About Us</h4><br />
          <h2>Team Techinfo</h2>
          <p className='text-justify'>We have been providing digital soloution for 8 years, considering your business
            needs and comfort. We are expert in developing dynamic websites, point of sale applications, online store, clinic application and school management system.
            Our product is reliable, fast and upto date version.
          </p><br />
          <p className='text-justify'>
            We encourage you to login our school management application to explore, better experience and understanding.
          </p>
        </div>

        <br />
        <hr />
        <br />

      </div>
      <hr />
      <div className='row px-2' >
        <h3 className='text-center' style={{ color: "Blue" }}>School Management System <br/>(Demo Videos)</h3>
        <div className='col-md-6'>

    <p style={{fontSize:'22px'}}>We provide following in School Management System: -<br/>
     1. Student Management<br/>
     2. Student Attendance<br/>
     3. Fee Managment<br/>
     4. Exam Management<br/>
     5. Paper Preparation / Question Bank<br/>
     6. Date Sheet<br/>
     7. Daily Student Diary<br/>
     8. Important Announcement<br/>
     9. Staff Management<br/>
    10. Staff Salary<br/></p>
        </div>
        <div className='col-md-6 px-5 py-5'>
        <img src={dashboard} alt="Principal" className="img-fluid" />
          
          </div>

        <div className='col-md-6 px-5 py-5'>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/J2o-6FYSsjM?si=ZaLS6LN2gok-jZTt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

        <div className='col-md-6 px-5 py-5'>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/K4Z4PfVmqok?si=M7ZEBYdm6FEi7HoU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>




      </div>
    </>
  );
};

export default About;
